import React from 'react';

// Root Include
const Root = React.lazy(() => import('./pages/mos80/index'));

//Main Index
const Main = React.lazy(() => import('./pages/mos80/index'));

//Utility
const PagePrivacy = React.lazy(() => import('./pages/Pages/Utility/PagePrivacy'));
const PageTerms = React.lazy(() => import('./pages/Pages/Utility/PageTerms'));

//Special
const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));

const routes = [
    //Index Main
    { path: '/index', component: Main },

    //Utility

    //Index root

    { path: '/', component: Root, isWithoutLayout : false, exact : true },
    { component: PageError, isWithoutLayout : true, exact : false },

];

export default routes;
